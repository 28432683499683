/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(document).foundation(); // Foundation JavaScript
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  function gallery() {
    $('#gallery, #gallery-1, #gallery-2, #gallery-3, #gallery-4, #gallery-5, #gallery-6, #gallery-7').Chocolat({
      separator2: '- ',
      loop: true
    });
  }

  function scrollHash() {
    $("a").on('click', function (event) {
      if (this.hash !== "") {
        event.preventDefault();
        var hash = this.hash;
        $('html, body').animate({
          scrollTop: $(hash).offset().top - 200
        }, 800, function () {
          window.location.hash = hash;
        });
      }
    });
  }

  function owl() {
    $('.instrument-slider__carousel').owlCarousel({
        loop:true,
        margin:0,
        nav:false,
        items: 1,
        autoplay: true,
        autoplayTimeout:5000,
        animateOut: 'fadeOut',
        mouseDrag: false,
        lazyLoad: true
    });
    $('.single-instrument-slider__carousel').owlCarousel({
      loop: true,
      margin: 0,
      nav: true,
      items: 1,
      autoplay: true,
      autoplayTimeout: 5000,
      animateOut: 'fadeOut',
      mouseDrag: false,
      navText: []
    });
  }

  function reveal() {
    window.sr = ScrollReveal();
    sr.reveal('.scroll-reveal', {
      duration: 500,
      viewFactor: 0.2,
      scale: 1
    });
    sr.reveal('.stagger-reveal-intro', { duration: 500, scale: 1 }, 500);
    sr.reveal('.stagger-reveal-targets', { duration: 500, scale: 1 }, 500);
  }

  function menu() {
    $('.top-menu').mouseenter(function () {
      $(this).removeClass('top-menu--up').addClass('top-menu--down');
    });
  }

  function mediaList() {
    $('#news-sidebar__btn').click(function(e) {
      e.preventDefault;
      $(this).addClass('news-sidebar__btn--hidden');
      $('.news-sidebar__items').removeClass('news-sidebar__items--collapsed').addClass('news-sidebar__items--expanded');
    });
  }

  var didScroll;
  var lastScrollTop = 0;
  var delta = 0;
  var navbarHeight = $('.top-menu').outerHeight();

  setInterval(function () {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);

  function hasScrolled() {
    var st = $(this).scrollTop();

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta)
      return;

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight) {
      // Scroll Down
      $('.top-menu').removeClass('top-menu--down').addClass('top-menu--up');
    } else {
      // Scroll Up
      if (st + $(window).height() < $(document).height()) {
        $('.top-menu').removeClass('top-menu--up').addClass('top-menu--down');
      }
    }

    lastScrollTop = st;
  }

  $(document).ready(function(){
    owl();
    gallery();
    menu();
    reveal();
    mediaList();
    scrollHash();
  });

  $(window).scroll(function (event) {
    didScroll = true;
  });

})(jQuery);
